jQuery(function($) {

    /* initialize FlexNav */
    $(".flexnav").flexNav({
        'type': 'slide'
    });

    /* headroom */

    var options = {
        offset : 100,
        tolerance : {
            up : 10,
            down : 0
        }
    };

    var headroom  = new Headroom(document.querySelector(".desktopNav"), options);

    headroom.init();

    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
        trigger: ".headerImage",
        toggleClass: "active",
        start: "-10px top",
        end: "100%-=62px top",
        //markers: true,
        onLeave: ({progress, direction, isActive}) => {
            $("header").addClass('inverted');
        },
        onEnterBack: ({progress, direction, isActive}) => {
            $("header").removeClass('inverted');
        },
    });


    /* scroll top button */

    $('footer').append("<span class='scrollTop'></span>");

    $('.scrollTop').on('click', function() {
        $("html, body").animate({ scrollTop: 0 }, 'slow', 'swing', function() {
            $(window).trigger('scroll');
        });
        return false;
    });


    /* arrow in header */

    $('.arrow').on('click', function() {
        $position = $(".intro").offset();

        $("html, body").animate({ scrollTop: $position.top - 300 }, 'slow', 'swing');
        return false;
    });


    /* form "magic" */

    $( "#mailTwo" ).focus(function() {
        $(this).parent().addClass('active');
    });

    $(".callToAction input[type='text']").on('blur', function(){
      if( !$(this).val() ) {
        $(this).parent().removeClass('active');
      }
    }).on('focus', function(){
      $(this).parent().addClass('active');
    });

    /* form privacy */

    if($('#kontaktformular_1-checkbox-1').length == 1){

        var privacyLabel = $('#kontaktformular_1-checkbox-1').parent().find('span');
        var privacyElement = $('#kontaktformular_1-checkbox-1').closest('.row').find('div').last();
        var privacyElementText = $(privacyElement).find('p').html();

        $(privacyElement).hide();
        $(privacyLabel).html(privacyElementText);

    }

    /* reference scroll to (deactivated) */
    /*
    var showReferences = function() {
    var aTag = $("a[name='c12']");
        $('html,body').animate({
            scrollTop: aTag.offset().top
        }, '250');
    }

    $('#nav3').on('click', function(e) {
        e.preventDefault();
        if ($('body > div').hasClass('page1')) {
            showReferences();
        } else {
            window.location.href = $(this).attr('href') + '#references';
        }
    });

    var elem = $('#_' + window.location.hash.replace('#', ''));
    if(elem.selector == '#_references') {
        showReferences();
    }
    */
   
    /* form scroll top to prevent hidden input elements by nav */

    $('#kontaktformular_1 button').on('click', function(e) {
        var formPosition = window.pageYOffset || document.documentElement.scrollTop;

        setTimeout(function() {
            var formPositionNew = window.pageYOffset || document.documentElement.scrollTop;

            if( formPositionNew < formPosition ) {
                $("html, body").animate({ scrollTop: formPositionNew - 150 }, 'slow', 'swing');
            }
        }, 100);
    });

    /* select2 */

    $(".form-group select").select2({
        minimumResultsForSearch: Infinity,
        width: "style"
    });

    $('.input.checkbox').prev().hide();
    $('.form-group textarea').parent().parent().addClass('textareaForm');
    $('#kontaktformular-fileupload-1').parent().parent().addClass('csc-form-element-fileupload');

  /* cookieBanner */

  (function($){

      "use strict";

      var settings = {
          backgroundColor : 'white',
          html            : '<div id="privacyWrapper"><div id="privacyInner"><p></p></div></div>',
          innerWidth      : 1024,
          opacity         : 0,
          position        : 'top',
          closeValue      : 'OK',
          innerAttr       : {},
          buttonAttr      : {},
          wrapperAttr     : {},
          text            : {
              'de' : 'Wir verwenden Cookies, um unsere Website fortlaufend optimieren zu können. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zum Einsatz von Cookies auf dieser Website finden Sie in unserer <a href="#" id="notePrivacy" style="text-decoration: underline;">Datenschutzerklärung</a>.'
          }

      };
      var block;

      var privacy = {
          init: function() {
              if (privacy.readCookie('privacyOk')) {
                  return;
              }
              if (settings.position == 'top') {
                  block = $(settings.html).prependTo('body');
              } else {
                  block = $(settings.html).appendTo('body');
              }
              //$('#privacyWrapper').css('background-color', settings.backgroundColor);
              $.each(settings.innerAttr, function(key, value) {
                  $('#privacyInner').attr(key, value);
              });
              $.each(settings.wrapperAttr, function(key, value) {
                  $('#privacyWrapper').attr(key, value);
              });
              $('#privacyInner').find('p')
                                .html(settings.text[$('html').attr('lang')])
                                ;
              $('<a id="closePrivacy">' + settings.closeValue +  '</a>').appendTo('#privacyInner p').on('click', function() {
                  $('#privacyWrapper').slideUp(400, function() {
                      $(this).empty();
                      privacy.setCookie('privacyOk', true, 730);
                  });
              });
              $.each(settings.buttonAttr, function(key, value) {
                  $('#closePrivacy').attr(key, value);
              });
          },
          setCookie: function(name,value,days) {
              var expires = "";
              if (days) {
                  var date = new Date();
                  date.setTime(date.getTime() + (days*24*60*60*1000));
                  expires = "; expires=" + date.toUTCString();
              }
              document.cookie = name + "=" + date.getTime() + expires + "; path=/";
          },
          readCookie: function(name) {
              var nameEQ = name + "=";
              var ca = document.cookie.split(';');
              for(var i=0;i < ca.length;i++) {
                  var c = ca[i];
                  while (c.charAt(0)==' ') c = c.substring(1,c.length);
                  if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
              }
              return null;
          }
      }

      $.fn.privacy = function( options ) {

          settings = $.extend(settings, options);

          privacy.init();

          return this;
       };

  }( jQuery ));

  $('body').privacy({
      backgroundColor: '#ffffff',
      text: {
         'de' : 'Wir verwenden Cookies, um unsere Website fortlaufend optimieren zu können. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zum Einsatz von Cookies auf dieser Website finden Sie in unserer <a href="/de/footer/datenschutz.html" id="notePrivacy" style="text-decoration: underline;">Datenschutzerklärung</a>.',
      },
      buttonAttr: {}
  });

});
