jQuery(function($) {

	/* intro */

    if ($('.page1').length) {
        var headline = new SplitText("h1", {type:"lines"});
        var span = new SplitText(".headerText span", {type:"words"});

        var intro = gsap.timeline();

        intro.from(".headerText", {duration: .1, autoAlpha: 0})
             .from(headline.lines, {delay: .5, duration: .5, autoAlpha: 0, y: 10, stagger: .5})
             .from(span.words, {delay: .3, duration: .3, autoAlpha: 0, y: -10, stagger: {amount: .5, from: "edges"}});

    }

	if ($('.headerImage').length) {
		$(".arrow").on("webkitAnimationEnd oanimationend msAnimationEnd animationend", function() {
			$('.arrow').addClass("bounce-active").dequeue();
		});
	}

	if (!$.cookie('startpageIntro')) {
		$.cookie('startpageIntro', true, { expires: 180, path: '/' });
	} else {
		/* run following animations only once */
		$('.logo, .flexnav').addClass('no-animation');
	}

	/* iconText (Leistungen) */

	if ($('.iconText .content').length) {
		$('.iconText li, .iconText img, .iconText h3, .iconText p').addClass('hide');
		hunt(document.querySelectorAll('.iconText .content'), {
			in: function() {
				$(this).find('img, h3').addClass('fadeInUp-active');
				$(this).find('li, p').each(function(i, el){
					setTimeout(function(){
					   $(el).addClass('fadeInUp-active');
					}, 100 + ( i * 200 ));
				});
			},
			offset: -100
		});
	}

	/* address (Kontakt) */

	if ($('.address').length) {
		$('.address').find('.image, .addressText').addClass('hide');
		hunt(document.querySelectorAll('.address'), {
			in: function() {
				$(this).find('.image').addClass('fadeInLeft-active');
				$(this).find('.addressText').addClass('fadeInRight-active');
			},
			offset: -100
		});
	}

	/* Feature */

	if ($('a.feature').length) {
		$('a.feature').addClass('hide');
			hunt(document.querySelectorAll('a.feature'), {
			in: function() {
				//this.classList.remove('hide');
				this.classList.add('fadeIn-active');
			},
			offset: 0
		});
	}


	/* imageText (TEAM & ANFRAGE) */

	if ($('.animate').length) {

		 $('.animate .text').addClass('hide');
		 hunt(document.querySelectorAll('.animate .text'), {
		   in: function() {
		     this.classList.remove('hide');
		     this.classList.add('fadeInRight-active');
		   },
		   offset: 0
		 });

		 $('.animate .addressText').addClass('hide');
		 hunt(document.querySelectorAll('.animate .addressText'), {
		   in: function() {
		     this.classList.remove('hide');
		     this.classList.add('fadeInRight-active');
		   },
		   offset: 0
		 });

		 $('.animate .image').addClass('hide');
		 hunt(document.querySelectorAll('.animate .image'), {
		   in: function() {
		     this.classList.remove('hide');
		     this.classList.add('fadeInLeft-active');
		   },
		   offset: 0
		 });

	}

});
